import { Button, Input, Table, TableColumnType } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

interface DynamicJsonArrayInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const DynamicJsonArrayInput: React.FC<DynamicJsonArrayInputProps> = ({
  value,
  onChange
}) => {
  const [records, setRecords] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue) && parsedValue.length > 0) {
          setColumns(Object.keys(parsedValue[0]));
          setRecords(parsedValue);
        } else {
          setRecords([]);
          setColumns([]);
        }
      } catch (e) {
        console.error('Failed to parse JSON value:', e);
        setRecords([]);
        setColumns([]);
      }
    }
  }, [value]);

  const triggerChange = (newRecords: any[]) => {
    onChange?.(JSON.stringify(newRecords, null, 2));
  };

  const handleAdd = () => {
    const emptyRecord = columns.reduce(
      (acc, col) => ({
        ...acc,
        [col]: ''
      }),
      {}
    );

    const newRecords = [...records, emptyRecord];
    setRecords(newRecords);
    triggerChange(newRecords);
  };

  const handleRemove = (index: number) => {
    const newRecords = records.filter((_, i) => i !== index);
    setRecords(newRecords);
    triggerChange(newRecords);
  };

  const handleFieldChange = (index: number, column: string, value: string) => {
    const newRecords = [...records];
    newRecords[index] = {
      ...newRecords[index],
      [column]: value
    };
    setRecords(newRecords);
    triggerChange(newRecords);
  };

  const tableColumns: TableColumnType<any>[] = [
    ...columns.map(column => ({
      title: column.charAt(0).toUpperCase() + column.slice(1),
      dataIndex: column,
      key: column,
      render: (text: string, record: any, index: number) => (
        <Input
          value={text}
          onChange={e => handleFieldChange(index, column, e.target.value)}
          placeholder={column}
        />
      )
    })),
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (_, __, index: number) => (
        <Button
          type='text'
          icon={<MinusOutlined />}
          onClick={() => handleRemove(index)}
          danger
        />
      )
    }
  ];

  return (
    <div>
      <Table dataSource={records} columns={tableColumns} pagination={false} />
      <Button
        type='dashed'
        onClick={handleAdd}
        style={{ marginTop: 16, width: '100%' }}
        icon={<PlusOutlined />}>
        Add Row
      </Button>
    </div>
  );
};

export default DynamicJsonArrayInput;
