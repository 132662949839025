import {
  Config,
  ConfigurationType
} from '../../../types/data/configuration.type';
import { Form, FormInstance, Input, InputNumber, Modal, Switch } from 'antd';

import DraggableTable from '../../../components/table/draggableTable';
import DynamicJsonArrayInput from '../../../components/dynamicJsonArrayInput';
import React from 'react';
import capitalize from 'lodash.capitalize';
import uniq from 'lodash.uniq';

type EditConfigModalProps = {
  loading: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  data: Config;
  options: Config[];
  naicIds: string[];
};

const EditConfigModal = (props: EditConfigModalProps) => {
  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const [tableValue, setTableValue] = React.useState<any[]>([]);
  const handleTableValueChange = (value: any[]) => {
    setTableValue(value);
  };

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(data => {
        const data_: Config = {
          key: props.data.key,
          value:
            props.data.type === ConfigurationType.ARRAY
              ? tableValue
                  .filter(item => item.rank !== 0)
                  .map(item => item.value)
                  .join(',')
              : (data.value as string),
          description: data.description as string,
          type: props.data.type,
          id: props.data.id
        };
        props.handleOk(data_);
      })
      .catch(err => {
        console.log('Form has validation errors', err);
      });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const valueLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 22 }
  };
  if (props.data) {
    return (
      <div>
        <Modal
          className='editConfigModal'
          title={`Edit Config - ${props.data.key}`}
          visible={props.visible}
          onOk={handleFormSubmit}
          onCancel={props.handleCancel}
          okText={'Update Config'}
          okButtonProps={{ loading: props.loading }}
          cancelText={'Cancel'}
          cancelButtonProps={{
            disabled: props.loading
          }}
          destroyOnClose={true}>
          <Form
            ref={formModalRef}
            initialValues={{
              ...props.data,
              ...(props.data.type === ConfigurationType.ARRAY
                ? { value: uniq(props.data.value.split(',')) }
                : {}),
              ...(props.data.type === ConfigurationType.BOOLEAN
                ? { value: props.data.value === 'true' }
                : {}),
              type: capitalize(props.data.type)
            }}
            style={{ height: '70vh', overflowY: 'auto' }}>
            <Form.Item {...layout} name='key' label='Name'>
              <Input disabled />
            </Form.Item>
            <Form.Item
              {...layout}
              name='description'
              label='Description'
              rules={[
                {
                  required: false
                }
              ]}>
              <Input.TextArea rows={2} showCount maxLength={255} />
            </Form.Item>
            <Form.Item {...layout} name='type' label='Type'>
              <Input disabled />
            </Form.Item>

            {props.data.type === ConfigurationType.ARRAY && (
              <Form.Item {...valueLayout} name='Value' label='Value'>
                <DraggableTable
                  data={props.options}
                  onChange={handleTableValueChange}
                  boostedCarriers={props.data.value}
                  naicIds={props.naicIds}
                />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.BOOLEAN && (
              <Form.Item
                name='value'
                label='value'
                valuePropName='checked'
                rules={[
                  { required: true, message: 'Please provide a valid value' }
                ]}>
                <Switch checkedChildren='True' unCheckedChildren='False' />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.STRING && (
              <Form.Item
                name='value'
                label='value'
                rules={[
                  { required: true, message: 'Please provide a valid value' }
                ]}>
                <Input maxLength={10000} showCount />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.INTEGER && (
              <Form.Item
                name='value'
                label='value'
                rules={[
                  { required: true, message: 'Please provide a valid value' }
                ]}>
                <InputNumber />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.FLOAT && (
              <Form.Item
                name='value'
                label='value'
                rules={[
                  { required: true, message: 'Please provide a valid value' }
                ]}>
                <InputNumber step={0.01} />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.JSON && (
              <Form.Item
                name='value'
                label='value'
                rules={[
                  { required: true, message: 'Please provide a valid value' }
                ]}>
                <Input.TextArea rows={5} maxLength={10000} showCount />
              </Form.Item>
            )}

            {props.data.type === ConfigurationType.CUSTOM_JSON_HH_DISCOUNT && (
              <Form.Item
                name='value'
                label='value'
                rules={[
                  {
                    required: true,
                    message: 'Please provide a valid value',
                    validator: (_, value) => {
                      try {
                        const parsed = JSON.parse(value);
                        if (!Array.isArray(parsed)) {
                          return Promise.reject(
                            new Error('Value must be an array of records')
                          );
                        }
                        return Promise.resolve();
                      } catch (e) {
                        return Promise.reject(new Error('Invalid JSON format'));
                      }
                    }
                  }
                ]}>
                <DynamicJsonArrayInput />
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    );
  } else return null;
};

export default EditConfigModal;
