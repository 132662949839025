export type Config = {
  description: string;
  id: string;
  key: string;
  type: ConfigurationType;
  updatedAt?: Date;
  value: string;
};

export enum ConfigurationType {
  INTEGER = 'integer',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  STRING = 'string',
  JSON = 'json',
  ARRAY = 'array',
  CUSTOM_JSON_HH_DISCOUNT = 'custom_JSON_hh_discount'
}
